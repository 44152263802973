import Employee from '@/calendesk/models/DTO/Response/Employee'
import Service from '@/calendesk/models/DTO/Response/Service'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'

export default class BookingDialogModel {
  public service: Service
  public employee: Employee | null
  public serviceType: ServiceType | null
  public duration: number | null
  public date: string | null
  public time: string | null
  public customerTimeZone: string | null
  public selectedSimpleProductIds: number[] | null
  public userRequestedEmployee: boolean
  public location: ServiceLocation | null

  constructor (
    service: Service,
    employee: Employee | null,
    serviceType: ServiceType | null,
    duration: number | null,
    date: string | null,
    time: string | null,
    customerTimeZone: string | null = null,
    selectedSimpleProductIds: number[] | null = null,
    userRequestedEmployee = false,
    location: ServiceLocation | null = null
  ) {
    this.service = service
    this.employee = employee
    this.serviceType = serviceType
    this.duration = duration
    this.date = date
    this.time = time
    this.customerTimeZone = customerTimeZone
    this.selectedSimpleProductIds = selectedSimpleProductIds
    this.userRequestedEmployee = userRequestedEmployee
    this.location = location
  }

  public getDuration (): number {
    if (this.duration) {
      return this.duration
    } else if (this.serviceType) {
      return this.serviceType.duration
    }

    return this.service.duration
  }
}
