






























import mixins from 'vue-typed-mixins'
import Cta from '../mixins/Cta'

export default mixins(Cta).extend({
  name: 'Cta2'
})
